<template lang="pug">
div
  page-header(:title='title')
  form(@submit.prevent='gravar')
    .modal-body
      .form-horizontal
        vue-element-loading(:active='isActive', spinner='bar-fade-scale', color='#428BCA')
        input-form-text(textInput='Nome', :required='true', v-model='form.nome', :value='form.nome')
        .form-group
          label.col-sm-3.control-label.no-padding-right Tipo
          .col-sm-5
            select.form-control(v-model='form.tipo_id')
              option(value='') Selecione
              option(:value='d.id', v-for='d in opctipo') {{d.titulo}}
        .form-group
          label.col-sm-3.control-label.no-padding-right Cor
          .col-sm-9
            color-picker(:color='defaultColor', v-model='form.cor')
        .form-group
          select-form(v-model='form.ativo', :value='form.ativo', name='Ativo', url='/arquivos', id='id', titulo='nome', layout='true', optionText='Selecione', required='true')
    footer-button(:disabled='isActive')
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import FooterButton from '@/components/form/FooterButton'
import SelectForm from '@/components/form/SelectForm'
import ColorPicker from '@/components/form/colorPicker'
import Permission from '@/mixins/Permission'
import req from '@/components/request'
import { sucesso, erro } from '@/components/Message'

export default {
  components: {
    PageHeader,
    InputFormText,
    FooterButton,
    ColorPicker,
    SelectForm,
    VueElementLoading
  },
  mixins: [Permission],
  computed: {
    ...mapGetters(['user']),
    defaultColor() {
      return this.form.cor ? this.form.cor : '#FF0000'
    }
  },
  data() {
    return {
      title: 'Status',
      form: {
        nome: '',
        tipo_id: '',
        cor: '',
        ativo: ''
      },
      isActive: false,
      opctipo: [
        {
          id: 9,
          titulo: 'Agenda'
        },
        {
          id: 10,
          titulo: 'Venda'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['getLogsTotal']),
    gravar() {
      const body = this.form
      body.USER = this.user.id
      if (this.$route.params.id) {
        req(`/status/${this.$route.params.id}`, 'put', body)
          .then(() => {
            sucesso('Atualizado...')
            this.getLogsTotal(this.user.id)
            this.$router.push('/admin/status')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      } else {
        req('/status', 'post', body)
          .then(() => {
            sucesso('Atualizado...')
            this.getLogsTotal(this.user.id)
            this.$router.push('/admin/status')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      }
    },
    getDados() {
      req(`/status/${this.$route.params.id}`)
        .then(resp => {
          this.form.ativo = resp.ativo
          this.form.nome = resp.nome
          this.form.tipo_id = resp.tipo_id
          this.form.cor = resp.cor
          this.isActive = false
        })
        .catch(response => {
          console.log('error: ' + response)
          erro()
        })
    },
    verifPermissao() {
      if (this.$route.params.id) {
        if (!this.ACTION.e) {
          this.$router.push('/erro')
        }
      } else {
        if (!this.ACTION.c) {
          this.$router.push('/erro')
        }
      }
    }
  },
  mounted() {
    this.verifPermissao()
    if (this.$route.params.id) {
      this.isActive = true
      this.getDados()
    }
  }
}
</script>

<style lang="stylus" scoped></style>
